<script setup lang="ts">
  import {computed, onMounted, ref} from 'vue';

  const props = withDefaults(
    defineProps<{
      rating?: number;
      max?: number;
      size?: 'sm' | 'md' | 'lg' | 'xl';
    }>(),
    {
      rating: 0,
      max: 5,
      size: 'md',
    },
  );

  const iconSize = computed(() => {
    switch (props.size) {
      case 'sm':
        return 'w-4 h-4';
      case 'lg':
        return 'w-8 h-8';
      case 'xl':
        return 'w-12 h-12';
      default:
        return 'w-6 h-6';
    }
  });

  const percentage = computed(() => {
    return Number((props.rating / props.max) * 100).toFixed(0);
  });

  const percentageString = computed(() => {
    return `${percentage.value}%`;
  });

  const clipPathId = ref<string | undefined>(undefined);
  onMounted(() => {
    clipPathId.value = `clip-path-${Math.random()
      .toString(36)
      .substring(2, 9)}`;
  });
</script>

<template>
  <div class="rating-stars relative inline-flex">
    <svg class="absolute h-full w-full inset-0">
      <defs>
        <clipPath :id="clipPathId">
          <rect :width="percentageString" height="100%" />
        </clipPath>
      </defs>
    </svg>
    <template v-for="state in ['idle', 'active']" :key="state">
      <div
        class="flex flex-nowrap"
        :style="{
          'clip-path': state === 'active' ? `url(#${clipPathId})` : undefined,
        }"
        :class="{
          [`stars stars--${state}`]: true,
        }"
      >
        <template v-for="i in max" :key="i">
          <div
            :class="{
              [iconSize]: true,
              'text-yellow-400 dark:text-yellow-600': state === 'active',
              'text-gray-100 dark:text-gray-700': state === 'idle',
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </template>
      </div>
    </template>
    <span class="sr-only">Rating: {{ rating }} out of {{ max }} stars</span>
  </div>
</template>

<style scoped lang="scss">
  path {
    stroke-width: 1px;
    stroke: theme('colors.gray.300');
  }

  .stars--active {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    path {
      stroke: theme('colors.yellow.500');
    }
  }

  @media (prefers-color-scheme: dark) {
    path {
      stroke: theme('colors.gray.500');
    }

    .stars--active {
      path {
        stroke: theme('colors.yellow.500');
      }
    }
  }
</style>
